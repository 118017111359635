import { StylesConfig } from 'react-select';

export const firstWhenTakenGameDate = '2024-02-28';
export const firstWhereTakenGameDate = '2024-10-28';
export const firstWhenTakenMoviesGameDate = '2025-01-13';

export const categories = [
  'Nature',
  'Architecture',
  'Nightlife',
  'Landmarks',
  'Black&White',
  'Street scene',
  'Historic events',
  'Sports',
  'Celebrities',
  'Transportation',
  'Indigenous',
  'Party',
  'War',
  'Aviation',
  'Travel',
  'Prominent figures',
  'Science',
  'Construction',
  'Protest/Demonstration',
];

export const adminImageModalId = (url: string): string =>
  `admin-image-modal-${url}`;

export const imageUploadId = (url: string): string => `image-upload-${url}`;

export const reactSelectStyles: StylesConfig<string, true> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles) => {
    return {
      ...styles,
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#EFF8FF',
      borderRadius: '9999px',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#175CD3',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#2E90FA',
    ':hover': {
      backgroundColor: '#2E90FA',
      color: 'white',
    },
  }),
};
