export const lightTheme = {
  primary: '#ffc800',
  'primary-content': '#2E2C26',

  secondary: '#E5E5E5',
  'secondary-content': '#FFF6FF',

  accent: '#ffd966',
  'accent-content': '#FFF6FF',

  neutral: '#fff2cc',
  'neutral-content': '#FFF6FF',

  highlight: '#f8cf3a',

  muted: '#D1CFC7',

  'base-100': '#FFF',
  'base-200': '#f5f5f5',
  'base-300': '#808080',

  'base-content': '#000000',
  'base-100-content': '#000000',
  'base-200-content': '#000000',
  'base-300-content': '#000000',

  info: '#f5f5f5',
  'info-content': '#ffe599',

  success: '#479F91',
  'success-content': '#fff',

  warning: '#FAB251',
  'warning-content': '#000',

  error: '#F24949',
  'error-content': '#ffffff',
};

export const darkTheme = {
  primary: '#ffc800',
  'primary-content': '#2E2C26',

  secondary: '#624E04',
  'secondary-content': '#FFF6FF',

  accent: '#ffd966',
  'accent-content': '#FFF6FF',

  neutral: '#322E38',
  'neutral-content': '#FFF6FF',

  highlight: '#f8cf3a',

  muted: '#D1CFC7',

  'base-100': '#1A1A1A',
  'base-200': '#38372e',
  'base-300': '#FFFFFF',

  'base-content': '#FFF6FF',

  'base-100-content': '#FFF6FF',
  'base-200-content': '#FFF6FF',
  'base-300-content': '#FFF6FF',

  info: '#38372e',
  'info-content': '#FDF3CE',

  success: '#479F91',
  'success-content': '#fff',

  warning: '#FAB251',
  'warning-content': '#000',

  error: '#F24949',
  'error-content': '#fff',
};
