import { Guess } from '@wt/app/game/_components/types';
import { getUpdatedStats } from '@wt/app/game/_utils/getUpdatedStats';
import { loadAdScript } from '@wt/shared/hooks/useAds/useAds';
import {
  getIsLoggedIn,
  getUser,
  refreshTokens,
  syncStats,
} from '@wt/utilities/database';
import { nullFunc } from '@wt/utilities/nullFunction';
import { useRouter } from 'next/navigation';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  convertDbUserToUser,
  DatabaseUser,
  getUserStats,
  initialUser,
  parseUserFromLocalStorage,
  User,
} from './types';
import {
  clearLocalStorage,
  isSameUtcDay,
  setLocalStorage,
  whenTakenUserLocalStorageKey,
} from './utils';

interface UserContextType {
  user: User;
  updateUser: (updatedUser: Partial<User>) => void;
  loggedIn: boolean;
  logOut: () => void;
  updateStats: (todaysGuesses: Guess[], dayString: string) => void;
  showLoginTooltip: boolean;
  setShowLoginToolip: (show: boolean) => void;
}

const initialContextValue: UserContextType = {
  user: initialUser,
  updateUser: nullFunc,
  loggedIn: false,
  logOut: nullFunc,
  updateStats: nullFunc,
  showLoginTooltip: false,
  setShowLoginToolip: nullFunc,
};

const UserContext = createContext<UserContextType>(initialContextValue);

export const useUserProvider = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>(initialUser);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [showLoginTooltip, setShowLoginToolip] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    async function loadUser() {
      const isLoggedIn = getIsLoggedIn();
      const encodedRefreshTime = localStorage.getItem('wt-refreshDate');
      const refreshTime = encodedRefreshTime
        ? decodeURIComponent(encodedRefreshTime)
        : null;

      // load user from local storage
      const userFromLocalStorage = localStorage.getItem(
        whenTakenUserLocalStorageKey
      );

      // set user to be local storage user
      // if logged in this will be overwritten by db data
      if (userFromLocalStorage) {
        setUser(parseUserFromLocalStorage(userFromLocalStorage));
      } else {
        // if user has not been set in local storage, create
        localStorage.setItem(
          whenTakenUserLocalStorageKey,
          JSON.stringify(initialUser)
        );
      }

      if (isLoggedIn) {
        try {
          setLoggedIn(true);
          if (refreshTime && !isSameUtcDay(refreshTime)) {
            await refreshTokens();
          }

          const dbUser: DatabaseUser = await getUser();

          // if first login, sync stats saved in local storage to db
          if (dbUser.firstLogin) {
            if (userFromLocalStorage) {
              const parsedUser: User =
                parseUserFromLocalStorage(userFromLocalStorage);
              await syncStats(getUserStats(parsedUser));

              dbUser.gamesPlayed = parsedUser.gamesPlayed;
              dbUser.lastGamePlayed = parsedUser.lastGamePlayed;
              dbUser.currentStreak = parsedUser.currentStreak;
              dbUser.highestLocationScore = parsedUser.highestLocationScore;
              dbUser.highestYearScore = parsedUser.highestYearScore;
              dbUser.highestTotalScore = parsedUser.highestTotalScore;
              dbUser.totalLocationScore = parsedUser.totalLocationScore;
              dbUser.totalYearScore = parsedUser.totalYearScore;

              // Also redirect to the edit profile page as "Finish setting up your account"
              router.push('/account/edit#first-login');
            }
          }

          // set guesses, archive guesses, and custom guesses from db data
          setLocalStorage(dbUser);

          updateUser(convertDbUserToUser(dbUser));

          // load ads if not premium
          if (!dbUser.isPremium) {
            loadAdScript();
          }
        } catch (e) {
          logOut();
          alert(
            'Something went wrong. Please try again later. If the problem persists, please contact us.'
          );
        }
      } else {
        // if not logged in, load ads
        loadAdScript();
      }
    }

    loadUser();
  }, []);

  const logOut = (): void => {
    updateUser(initialUser);
    setLoggedIn(false);
    clearLocalStorage();
    loadAdScript();
  };

  // updates user in provider and local storage
  const updateUser = (updatedUserInfo: Partial<User>): void => {
    const newUser: User = {
      ...user,
      ...updatedUserInfo,
    };

    setUser(newUser);
    localStorage.setItem(whenTakenUserLocalStorageKey, JSON.stringify(newUser));
  };

  const updateStats = (todaysGuesses: Guess[], dayString: string): void => {
    // don't update stats multiple times
    if (user.lastGamePlayed === dayString) return;

    const newStats = getUpdatedStats(
      getUserStats(user),
      todaysGuesses,
      dayString
    );

    updateUser(newStats);
    if (loggedIn) syncStats(newStats);
    return;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
        loggedIn,
        logOut,
        updateStats,
        showLoginTooltip,
        setShowLoginToolip,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
