import { nullFunc } from '@wt/utilities/nullFunction';
import { createContext, useContext } from 'react';

export interface WTBotSettings {
  botGuess: boolean;
  imageAnalysis: boolean;
  historicalContext: boolean;
}
export interface SettingsStateProps {
  distanceUnit: 'km' | 'miles' | string;
  showAnswers: boolean;
  showDayShift: boolean;
  dayShiftCount: number;
  WTBot: WTBotSettings;
}
export const defaultSettings: SettingsStateProps = {
  distanceUnit: 'km',
  showAnswers: false,
  showDayShift: false,
  dayShiftCount: 0,
  WTBot: {
    botGuess: true,
    imageAnalysis: true,
    historicalContext: true,
  },
};

type SettingPartialFunc =
  | ((newData: Partial<SettingsStateProps>) => void)
  | (() => void);

export type SettingsContextProps = [
  SettingsStateProps | undefined,
  SettingPartialFunc,
  boolean,
  (b: boolean) => void,
];
export const SettingsContext = createContext<SettingsContextProps>([
  defaultSettings,
  nullFunc,
  false,
  nullFunc,
]);

export default function useSettingsContext() {
  return useContext(SettingsContext);
}
