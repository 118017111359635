import { setArchiveCompleted, setDailyCompleted } from '@wt/app/archive/utils';
import { setCommunityCompleted } from '@wt/app/community/utils';
import { communityGuessesStorageKey } from '@wt/app/community/utils';
import { GameMode } from '@wt/game/utils/types';
import { PuzzleLocation } from '@wt/shared/components/maps/mapkit/types';
import { Coordinate } from '@wt/shared/libs/mapkit';
import {
  getIsLoggedIn,
  saveArchiveGuesses,
  saveCommunityFinished,
  saveCommunityGuesses,
  saveCommunityRoundFinished,
  saveDailyGuesses,
  saveDailyHintsUsed,
  saveScoresByHintsUsed,
  updateRoundStats,
} from '@wt/utilities/database';

import { getGameScore } from '../_utils/getGameScore';
import { PUZZLE_LENGTH } from './PuzzleLoader';
import { getHintType } from '@wt/game/providers/hints/HintsProvider';

export interface PuzzleResultsState {
  date: string;
  device: string;
  guesses: Guess[] | undefined;
  score: number;
  round: number;
  currentRound?: number;
  puzzle: PuzzleLocation[];
}

export interface Guess {
  puzzle: PuzzleLocation;
  year: number;
  location: Coordinate;
  locationSecondary?: Coordinate;
  correctCountry?: boolean;
  hintsUsed?: string[];
}

export type ScoresByHintsUsed = {
  location: {
    totals: number[];
    max: number[];
    count: number[];
  };
  year: {
    totals: number[];
    max: number[];
    count: number[];
  };
  locationCombined: {
    totals: number[];
    max: number[];
    count: number[];
  };
  yearCombined: {
    totals: number[];
    max: number[];
    count: number[];
  };
  totalCombined: {
    totals: number[];
    max: number[];
    count: number[];
  };
};

export const dailyGuessesStorageKey = 'daily-guesses';
export const hintsUsedStorageKey = 'daily-hints-used-2';
export const scoresByHintsUsedStorageKey = 'scores-by-hints-used';
export const archiveGuessesStorageKey = 'archive-daily-guesses';

export function loadAllGuesses(
  gameMode: GameMode = 'daily'
): Record<string, Guess[]> {
  let storedGuesses;
  if (gameMode == 'daily') {
    storedGuesses = localStorage.getItem(dailyGuessesStorageKey);
  } else if (gameMode == 'archive') {
    storedGuesses = localStorage.getItem(archiveGuessesStorageKey);
  } else if (gameMode == 'community') {
    storedGuesses = localStorage.getItem(communityGuessesStorageKey);
  }
  try {
    return storedGuesses ? JSON.parse(storedGuesses) : {};
  } catch (e) {
    // If there is invalid JSON in the db, don't break when parsing it
    return {};
  }
}

export function saveGuesses(
  puzzleId: string,
  guesses: Guess[],
  gameMode: GameMode
): void {
  const isLoggedIn = getIsLoggedIn();

  if (gameMode != 'community' && guesses.length == PUZZLE_LENGTH) {
    // hints used and scores also here...
    const scores = guesses.map((g) => getGameScore(g).score);
    updateRoundStats({ guesses, gameMode, scores });
  }

  if (gameMode == 'archive' || gameMode == 'community') {
    if (puzzleId === undefined) {
      throw new Error('Puzzle id is undefined');
    }
    const allGuesses = loadAllGuesses(gameMode);
    const newGuesses = JSON.stringify({
      ...allGuesses,
      [puzzleId]: guesses,
    });

    if (gameMode == 'archive') {
      localStorage.setItem(archiveGuessesStorageKey, newGuesses);
      if (isLoggedIn) saveArchiveGuesses({ archiveGuesses: newGuesses });

      if (guesses.length === PUZZLE_LENGTH) {
        setArchiveCompleted(puzzleId, isLoggedIn);
      }
    } else if (gameMode == 'community') {
      localStorage.setItem(communityGuessesStorageKey, newGuesses);

      if (isLoggedIn) saveCommunityGuesses({ communityGuesses: newGuesses });
      if (guesses.length === PUZZLE_LENGTH) {
        setCommunityCompleted(puzzleId, isLoggedIn);
        // Save score to db
        let totalLocationScore = 0;
        let totalYearScore = 0;
        guesses.forEach((pg) => {
          const ps = getGameScore(pg);
          totalLocationScore += ps.score.distance;
          totalYearScore += ps.score.year;
        });
        saveCommunityFinished({
          slug: puzzleId,
          totalLocationScore,
          totalYearScore,
        });
      } else {
        // increment timesPlayed for community games after each guess
        saveCommunityRoundFinished({
          slug: puzzleId,
          round: guesses.length,
        });
      }
    }
    return;
  }

  // only save todays guesses
  const newGuesses = JSON.stringify({
    [puzzleId]: guesses,
  });
  localStorage.setItem(dailyGuessesStorageKey, newGuesses);
  if (isLoggedIn) saveDailyGuesses({ dailyGuesses: newGuesses });
  if (guesses.length === PUZZLE_LENGTH) {
    setDailyCompleted(puzzleId, isLoggedIn);
  }

  // at end of game, update the scoresByHintsUsed structure
  if (gameMode == 'daily' && guesses.length == PUZZLE_LENGTH) {
    updateScoresByHintsUsed(isLoggedIn, guesses);
  }
}

function initialiseScoreByHintsUsedArray(
  container: any,
  key: string,
  maxNumHints: number
) {
  container[key] ||= {};
  const base = [];
  for (let i = 0; i < maxNumHints + 1; i++) {
    base.push(0);
  }
  container[key].totals ||= [...base];
  container[key].max ||= [...base];
  container[key].count ||= [...base];
}

function setScoreByHintsUsedArray(obj: any, hintsUsed: number, score: number) {
  obj.totals[hintsUsed] = (obj.totals[hintsUsed] ?? 0) + score;
  obj.max[hintsUsed] = Math.max(obj.max[hintsUsed] ?? 0, score);
  obj.count[hintsUsed] = (obj.count[hintsUsed] ?? 0) + 1;
}

function updateScoresByHintsUsed(isLoggedIn: boolean, guesses: Guess[]) {
  let data: any = {};
  try {
    data = JSON.parse(
      localStorage.getItem(scoresByHintsUsedStorageKey) || '{}'
    );
  } catch (e) {
    // Ignore error
  }

  initialiseScoreByHintsUsedArray(data, 'location', 1);
  initialiseScoreByHintsUsedArray(data, 'year', 1);
  initialiseScoreByHintsUsedArray(data, 'locationCombined', 5);
  initialiseScoreByHintsUsedArray(data, 'yearCombined', 5);
  initialiseScoreByHintsUsedArray(data, 'totalCombined', 10);

  let totalYearHintsUsed = 0;
  let totalYearScore = 0;
  let totalLocationHintsUsed = 0;
  let totalLocationScore = 0;
  for (const guess of guesses) {
    const ps = getGameScore(guess);
    let locationHintUsed = false;
    let yearHintUsed = false;
    if (guess.hintsUsed) {
      for (const hint of guess.hintsUsed) {
        if (getHintType(hint) == 'location') {
          locationHintUsed = true;
          totalLocationHintsUsed++;
        } else if (getHintType(hint) == 'year') {
          yearHintUsed = true;
          totalYearHintsUsed++;
        }
      }
    }
    totalYearScore += ps.score.year;
    totalLocationScore += ps.score.distance;
    // Add data for this single guess
    setScoreByHintsUsedArray(
      data.location,
      locationHintUsed ? 1 : 0,
      ps.score.distance
    );
    setScoreByHintsUsedArray(data.year, yearHintUsed ? 1 : 0, ps.score.year);
  }
  // Add combined data
  setScoreByHintsUsedArray(
    data.locationCombined,
    totalLocationHintsUsed,
    totalLocationScore
  );
  setScoreByHintsUsedArray(
    data.yearCombined,
    totalYearHintsUsed,
    totalYearScore
  );
  setScoreByHintsUsedArray(
    data.totalCombined,
    totalYearHintsUsed + totalLocationHintsUsed,
    totalYearScore + totalLocationScore
  );

  localStorage.setItem(scoresByHintsUsedStorageKey, JSON.stringify(data));

  if (isLoggedIn) saveScoresByHintsUsed(JSON.stringify(data));
}

export function getScoresByHintsUsed(): ScoresByHintsUsed {
  let data: any = {};
  try {
    data = JSON.parse(
      localStorage.getItem(scoresByHintsUsedStorageKey) || '{}'
    );
  } catch (e) {
    // Ignore error
  }
  return data;
}

export function saveHints(guess: Guess, gameMode: GameMode) {
  if (gameMode != 'daily') return;

  const isLoggedIn = getIsLoggedIn();
  // Save hints usage
  const hintsUsedRaw = localStorage.getItem(hintsUsedStorageKey);
  let hintsUsed: Record<string, number> = {};
  if (hintsUsedRaw) {
    hintsUsed = JSON.parse(hintsUsedRaw);
  }
  hintsUsed['all'] ||= 0;
  hintsUsed['all']++;
  hintsUsed['allWithHint'] ||= 0;
  if (guess.hintsUsed) {
    if (guess.hintsUsed.length > 0) {
      hintsUsed['allWithHint']++;
    }
    for (const hint of guess.hintsUsed) {
      hintsUsed[hint] ||= 0;
      hintsUsed[hint]++;
    }
  }
  hintsUsed['all'] ||= 0;
  hintsUsed['all']++;
  localStorage.setItem(hintsUsedStorageKey, JSON.stringify(hintsUsed));
  if (isLoggedIn) saveDailyHintsUsed(hintsUsed);
}
