'use client';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import { WhenTakenLogo } from '@wt/game/assets/logo/WhenTakenLogo';
import { WhenTakenMoviesLogo } from '@wt/game/assets/logo/WhenTakenMoviesLogo';
import { WhereTakenLogo } from '@wt/game/assets/logo/WhereTakenLogo';
import { useBannerAdOpen } from '@wt/game/providers/bannerAdOpen/BannerAdOpenProvider';
import useSettingsContext from '@wt/game/providers/settings/types';
import { useUserProvider } from '@wt/game/providers/user/UserProvider';
import { getSiteMode } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { ReactNode, useMemo } from 'react';

import { AccountAvatar } from './AccountAvatar';
import { UploadButton } from './UploadButton';

const PageHeader = (props: { children?: ReactNode; gamePage?: boolean }) => {
  const { adOpen } = useBannerAdOpen();
  const { user } = useUserProvider();

  const top = useMemo(() => {
    return adOpen ? 'top-[50px] lg:top-[90px]' : 'top-1';
  }, [adOpen]);

  const headerStyles = props.gamePage
    ? 'max-w-screen-2xl mx-auto wideDesktop:px-8 border-box'
    : '';

  return (
    <div
      className={`sticky ${top} z-40 mb-2 flex min-h-[53px] items-center justify-center border-b border-solid border-secondary bg-base-100 px-4 py-2 md:mb-0 md:min-h-[62px] md:px-8 lg:min-h-[75px]`}
    >
      {props.gamePage && !user.isPremium && (
        <div className="hidden min-w-[320px] desktop:flex">
          {/** Sidebar ad space */}
        </div>
      )}
      <header
        className={`flex w-full flex-row items-center justify-between text-2xl md:text-4xl ${headerStyles}`}
      >
        <Link href="/" className="max-w-[280px] flex-1">
          {getSiteMode() == 'whentaken' && (
            <WhenTakenLogo gamePage={props.gamePage ?? false} />
          )}
          {getSiteMode() == 'whentaken-movies' && (
            <WhenTakenMoviesLogo gamePage={props.gamePage ?? false} />
          )}
          {getSiteMode() == 'wheretaken' && (
            <WhereTakenLogo gamePage={props.gamePage ?? false} />
          )}
        </Link>
        {props.children}
        <HeaderIconButtons />
      </header>
    </div>
  );
};

export default PageHeader;

const HeaderIconButtons = () => {
  const [,,, setSettingsModalOpen] = useSettingsContext();

  return (
    <div className="flex flex-row gap-2">
      <Link href={'/teuteuf-games'}>
        <SportsEsportsOutlinedIcon
          fontSize="large"
          className="hidden md:flex"
        />
        <SportsEsportsOutlinedIcon
          fontSize="medium"
          className="flex md:hidden"
        />
      </Link>
      <UploadButton />
      <Link href={'/stats'}>
        <InsertChartOutlinedIcon fontSize="large" className="hidden md:flex" />
        <InsertChartOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
      <Link href={'#'} onClick={() => setSettingsModalOpen(true)}>
        <SettingsOutlinedIcon fontSize="large" className="hidden md:flex" />
        <SettingsOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
      <AccountAvatar />
    </div>
  );
};
