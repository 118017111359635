import Icon from './icon';

export const WhereTakenLogo = (props: { gamePage?: boolean }) => {
  const textClasses = props.gamePage ? '2xl:flex' : 'md:flex';

  return (
    <div className="relative top-1 inline-flex flex-row items-center gap-1.5">
      <div>
        <Icon width="1em" height="1em" />
      </div>
      <div className={`hidden text-2xl ${textClasses} 2xl:text-4xl`}>
        <strong className="font-semibold leading-none">Where</strong>
        <span className="font-light  leading-none">Taken</span>
      </div>
    </div>
  );
};
