'use client';

import { nullFunc } from '@wt/utilities/nullFunction';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

interface RewardedAdsContextType {
  runRewardedAd: (onGrant: () => void) => void;
}

const initialContextValue: RewardedAdsContextType = {
  runRewardedAd: nullFunc,
};

const RewardedAdsContext =
  createContext<RewardedAdsContextType>(initialContextValue);

export const useRewardedAds = () => useContext(RewardedAdsContext);

export const RewardedAdsProvider = ({ children }: { children: ReactNode }) => {
  const adState = useRef<{
    adsShown: number;
    onGrant: (() => void) | null;
  }>({
    adsShown: 0,
    onGrant: null,
  });

  const runRewardedAd = useCallback((onGrant: () => void) => {
    try {
      window.googletag.cmd.push(() => {
        googletag.pubads().set('page_url', 'whentaken.com');

        const rewardedSlot = googletag
          .defineOutOfPageSlot(
            '/22152718,22828237697/Whentaken_RewardedAds_web',
            googletag.enums.OutOfPageFormat.REWARDED
          )
          ?.addService(googletag.pubads());
        if (!rewardedSlot) {
          return;
        }

        rewardedSlot.setForceSafeFrame(true);
        googletag.enableServices();

        if (adState.current.onGrant) {
          googletag
            .pubads()
            .removeEventListener(
              'rewardedSlotGranted',
              adState.current.onGrant
            );
        }

        adState.current.onGrant = onGrant;
        googletag
          .pubads()
          .addEventListener('rewardedSlotGranted', adState.current.onGrant);

        // TODO : If this is never called, then we should just grant the user anyway
        // TODO : What happens if this is added multiple times?
        if (adState.current.adsShown == 0) {
          googletag
            .pubads()
            .addEventListener(
              'rewardedSlotReady',
              (evt: googletag.events.RewardedSlotReadyEvent) => {
                // The ad is ready to be shown to the user
                evt.makeRewardedVisible();
              }
            );
          googletag
            .pubads()
            .addEventListener(
              'rewardedSlotClosed',
              (evt: googletag.events.RewardedSlotClosedEvent) => {
                // The user closed the ad (prematurely or otherwise)
                // Destroy the slot
                googletag.destroySlots([evt.slot]);
              }
            );
        }

        googletag.pubads().refresh([rewardedSlot]);

        adState.current.adsShown++;
        googletag.display(rewardedSlot);
      });
    } catch (e) {
      console.error(e);
      // In case of error, just grant the user
      onGrant();
    }
  }, []);

  return (
    <RewardedAdsContext.Provider value={{ runRewardedAd }}>
      {children}
    </RewardedAdsContext.Provider>
  );
};
