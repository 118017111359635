import { Logo } from './logo';

export const WhenTakenMoviesLogo = (props: { gamePage?: boolean }) => {
  const textClasses = props.gamePage ? '2xl:flex' : 'md:flex';

  return (
    <div className="relative top-1 inline-flex flex-row flex-1 w-full items-center gap-1.5">
      <img src="/assets/images/clapperboard.png" className="size-10" />
      <div className={`hidden w-0 max-w-[232px] flex-1 ${textClasses}`}>
        <Logo />
      </div>
    </div>
  );
};
