import {
  archiveGamesCompletedStorageKey,
  dailyGamesCompletedStorageKey,
} from '@wt/app/archive/utils';
import {
  communityGamesCompletedStorageKey,
  communityGamesVotesStorageKey,
  communityGuessesStorageKey,
} from '@wt/app/community/utils';
import {
  archiveGuessesStorageKey,
  dailyGuessesStorageKey,
  hintsUsedStorageKey,
  scoresByHintsUsedStorageKey,
} from '@wt/app/game/_components/types';

import { DatabaseUser } from './types';

export function isSameUtcDay(refreshTimeString: string): boolean {
  // Parse the refreshTime as a Date object
  const refreshDate = new Date(refreshTimeString);

  // Get the current date in UTC
  const currentDate = new Date();

  // Compare the year, month, and day
  return (
    refreshDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
    refreshDate.getUTCMonth() === currentDate.getUTCMonth() &&
    refreshDate.getUTCDate() === currentDate.getUTCDate()
  );
}

export const whenTakenUserLocalStorageKey = 'wt-user';

export const setLocalStorage = (dbUser: DatabaseUser): void => {
  localStorage.setItem(dailyGuessesStorageKey, dbUser.dailyGuesses);
  localStorage.setItem(
    dailyGamesCompletedStorageKey,
    dbUser.dailyGamesCompleted
  );
  localStorage.setItem(dailyGuessesStorageKey, dbUser.dailyGuesses);
  localStorage.setItem(archiveGuessesStorageKey, dbUser.archiveGuesses);
  localStorage.setItem(
    hintsUsedStorageKey,
    JSON.stringify(dbUser.dailyHintsUsed)
  );
  localStorage.setItem(scoresByHintsUsedStorageKey, dbUser.scoresByHintsUsed);
  localStorage.setItem(
    archiveGamesCompletedStorageKey,
    dbUser.archiveGamesCompleted
  );
  localStorage.setItem(communityGuessesStorageKey, dbUser.communityGuesses);
  localStorage.setItem(
    communityGamesCompletedStorageKey,
    dbUser.communityGamesCompleted
  );
  return;
};

export const clearLocalStorage = (): void => {
  localStorage.removeItem('wt-jwt');
  localStorage.removeItem('wt-jwt2');
  localStorage.removeItem('wt-refreshToken');
  localStorage.removeItem('wt-isLoggedIn');
  localStorage.removeItem('wt-refreshDate');
  localStorage.removeItem(dailyGuessesStorageKey);
  localStorage.removeItem(dailyGamesCompletedStorageKey);
  localStorage.removeItem(archiveGuessesStorageKey);
  localStorage.removeItem(archiveGamesCompletedStorageKey);
  localStorage.removeItem(communityGuessesStorageKey);
  localStorage.removeItem(communityGamesCompletedStorageKey);
  localStorage.removeItem(communityGamesVotesStorageKey);
  return;
};
