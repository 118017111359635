"use client";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useSettingsContext, {
  defaultSettings,
} from '@wt/game/providers/settings/types';
import { getSiteMode, getSiteName } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { useTheme } from 'next-themes';
import { ChangeEvent } from 'react';

function SettingsModal() {
  const [, , settingsModalOpen, setSettingsModalOpen] = useSettingsContext();

  return (
    <Modal
      open={settingsModalOpen}
      onClose={() => setSettingsModalOpen(false)}
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        '&.MuiBox-root': {
          border: 'none',
        },
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <Box
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <SettingsContent />
      </Box>
    </Modal>
  );
}

export default SettingsModal;

const SettingsContent = () => {
  const { theme, setTheme } = useTheme();
  const [settings, setSettings, , setSettingsModalOpen] = useSettingsContext();

  const handleDistanceUnitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      distanceUnit: e.currentTarget.value,
    });
  };

  const updateTheme = (e: ChangeEvent<HTMLInputElement>) => {
    setTheme(e.currentTarget.value);
  };

  return (
    <div className="m-4 flex min-w-[300px] flex-col space-y-6 rounded-3xl bg-base-200 p-6 text-lg md:min-w-[350px]">
      <div className="flex w-full flex-row items-center">
        <div className="grow text-center">
          <span className="text-xl font-medium lg:text-4xl">Settings</span>
        </div>
        <Link href={'#'} onClick={() => setSettingsModalOpen(false)}>
          <CloseRoundedIcon className="m-0 fill-current text-2xl leading-none" />
        </Link>
      </div>
      <div className="flex w-full border-b-2 border-secondary" />

      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div>Units</div>
        <div className="flex flex-row items-center">
          <input
            type="radio"
            aria-label="Distance Unit Kilometres Option"
            id="km"
            name="km"
            value="km"
            // km is default
            checked={settings?.distanceUnit !== 'miles'}
            onChange={handleDistanceUnitChange}
            className="mr-2"
          />
          <label htmlFor={'km'} className="pr-6 text-sm">
            Kilometres
          </label>
          <input
            type="radio"
            aria-label="Distance Unit Miles Option"
            id="miles"
            name="miles"
            value="miles"
            checked={settings?.distanceUnit === 'miles'}
            onChange={handleDistanceUnitChange}
            className="mr-2"
          />
          <label htmlFor={'miles'} className="text-sm">
            Miles
          </label>
        </div>
      </div>

      <div className="w-full border-b-2 border-secondary" />

      {getSiteMode() != 'whentaken-movies' && (
        <div className="flex w-full flex-row items-center justify-between">
          <div>Theme</div>
          <div className="flex flex-row items-center">
            <input
              type="radio"
              aria-label="Theme Dark Option"
              id="dark"
              name="dark"
              value="dark"
              checked={theme === 'dark'}
              onChange={updateTheme}
              className="mr-2"
            />
            <label htmlFor={'dark'} className="pr-6 text-sm">
              Dark
            </label>
            <input
              type="radio"
              aria-label="Theme Light Option"
              id="light"
              name="light"
              value="light"
              checked={theme === 'light'}
              onChange={updateTheme}
              className="mr-2"
            />
            <label htmlFor={'light'} className="text-sm">
              Light
            </label>
          </div>
        </div>
      )}

      {getSiteMode() == 'whentaken' && (
        <>
          <div className="w-full border-b-2 border-secondary" />
          <div className="flex w-full flex-row items-start justify-between gap-4">
            <div className="flex flex-col items-start gap-1">
              <p>{getSiteName()} Bot</p>
              <Link
                href={'/#faq'}
                onClick={() => setSettingsModalOpen(false)}
                className="text-sm underline"
              >
                FAQ
              </Link>
            </div>
            <div className="flex flex-col items-start gap-2">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  aria-label="Bot Guess Checkbox"
                  id="bot-guess"
                  name="bot-guess"
                  value="bot-guess"
                  checked={settings?.WTBot?.botGuess !== false} // default to true
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      WTBot: {
                        ...(settings?.WTBot ?? defaultSettings.WTBot),
                        botGuess: e.target.checked,
                      },
                    });
                  }}
                />
                <label htmlFor="bot-guess" className="text-sm">
                  Bot Guess
                </label>
              </div>

              <div className="flex gap-2">
                <input
                  type="checkbox"
                  aria-label="Image Analysis Checkbox"
                  id="image-analysis"
                  name="image-analysis"
                  value="image-analysis"
                  checked={settings?.WTBot?.imageAnalysis !== false} // default to true
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      WTBot: {
                        ...(settings?.WTBot ?? defaultSettings.WTBot),
                        imageAnalysis: e.target.checked,
                      },
                    });
                  }}
                />
                <label htmlFor="image-analysis" className="text-sm">
                  Image Analysis
                </label>
              </div>

              <div className="flex gap-2">
                <input
                  type="checkbox"
                  aria-label="Historical Context Checkbox"
                  id="historical-context"
                  name="historical-context"
                  value="historical-context"
                  checked={settings?.WTBot?.historicalContext !== false} // default to true
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      WTBot: {
                        ...(settings?.WTBot ?? defaultSettings.WTBot),
                        historicalContext: e.target.checked,
                      },
                    });
                  }}
                />
                <label htmlFor="historical-context" className="text-sm">
                  Historical Context
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};