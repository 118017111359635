import { GameRound } from '@wt/app/account/games/_components/GameForm/types';
import { CommunityGame } from '@wt/app/community/types';
import { DatabaseUser, GameStats, User } from '@wt/game/providers/user/types';
import { getSiteMode } from './siteMode';
import { LocationHint, YearHint } from '@wt/game/providers/hints/HintsProvider';
import { Guess } from '@wt/app/game/_components/types';
import { GameMode } from '@wt/game/utils/types';
import { BotData } from '@wt/app/admin/_components/providers/botForm/types';
import { ErrorInfo } from 'react';

export const getAuthServerUrl = (): string => {
  const href = window.location.href;
  return 'https://auth.teuteuf.fr';
};

export const getServerUrl = (): string => {
  const href = window.location.href;
  if (href.includes('localhost')) {
    return 'http://localhost:7001';
  } else {
    return 'https://auth.whentaken.com';
  }
};

const recentlyStarted = new Set();

function makeAuthServerAuthorizationHeader() {
  return {
    authorization: 'Bearer ' + localStorage.getItem('wt-jwt'),
  };
}

function makeAuthorizationHeader() {
  return {
    authorization: 'Bearer ' + localStorage.getItem('wt-jwt2'),
  };
}

export const getIsLoggedIn = () => {
  return !!(
    localStorage.getItem('wt-isLoggedIn') && localStorage.getItem('wt-jwt')
  );
};

export const refreshTokens = async () => {
  const response = await fetch(`${getAuthServerUrl()}/auth/refresh`, {
    method: 'POST',
    headers: {
      ...makeAuthServerAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: localStorage.getItem('wt-jwt'),
      refreshToken: localStorage.getItem('wt-refreshToken'),
    }),
  });
  if (response.status < 200 || response.status > 299) {
    return false;
  }
  const { token, refreshToken } = (await response.json()) as any;
  localStorage.setItem('wt-jwt', token);
  localStorage.setItem('wt-refreshToken', refreshToken);
  localStorage.setItem('wt-isLoggedIn', '1');
  const now = new Date();
  const utcString = now.toUTCString();
  localStorage.setItem('wt-refreshDate', utcString);
  return true;
};

export const getUser = async () => {
  const response = await fetch(
    `${getServerUrl()}/api/authuser?token=${localStorage.getItem('wt-jwt')}`,
    {
      method: 'GET',
      headers: {
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    throw new Error('Could not get user');
  }
  const dbUser = await response.json<DatabaseUser>();
  if ((dbUser as any).token) {
    localStorage.setItem('wt-jwt2', (dbUser as any).token);
  }
  return dbUser;
};

export const syncStats = (stats: GameStats): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/syncstats`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      gamesPlayed: stats.gamesPlayed,
      lastGamePlayed: stats.lastGamePlayed,
      currentStreak: stats.currentStreak,
      highestLocationScore: stats.highestLocationScore,
      highestYearScore: stats.highestYearScore,
      highestTotalScore: stats.highestTotalScore,
      totalLocationScore: stats.totalLocationScore,
      totalYearScore: stats.totalYearScore,
    }),
  });
};

export const saveDailyGuesses = ({
  dailyGuesses,
}: {
  dailyGuesses: string;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/savedailyguesses`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      dailyGuesses: dailyGuesses,
    }),
  });
};

export const saveDailyHintsUsed = (
  hintsUsed: Record<string, number>
): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/savedailyhintsused`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      hintsUsed,
    }),
  });
};

export const saveScoresByHintsUsed = (
  scoresByHintsUsed: string
): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/savescoresbyhintsused`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      scoresByHintsUsed,
    }),
  });
};

export const saveDailyGamesCompleted = ({
  dailyGamesCompleted,
}: {
  dailyGamesCompleted: string;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/savedailygamescompleted`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      dailyGamesCompleted: dailyGamesCompleted,
    }),
  });
};

export const saveArchiveGuesses = ({
  archiveGuesses,
}: {
  archiveGuesses: string;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/savearchiveguesses`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      archiveGuesses: archiveGuesses,
    }),
  });
};

export const saveArchiveGamesCompleted = ({
  archiveGamesCompleted,
}: {
  archiveGamesCompleted: string;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/savearchivegamescompleted`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      archiveGamesCompleted: archiveGamesCompleted,
    }),
  });
};

export const saveCommunityGuesses = ({
  communityGuesses,
}: {
  communityGuesses: string;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/communitygames/saveguesses`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      communityGuesses,
    }),
  });
};

export const saveCommunityStarted = (props: { slug: string }) => {
  if (recentlyStarted.has(props.slug)) return null;
  recentlyStarted.add(props.slug);
  return fetch(`${getServerUrl()}/communitygames/startgame`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify(props),
  });
};

export const saveCommunityRoundFinished = (props: {
  slug: string;
  round: number;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/communitygames/finishround`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify(props),
  });
};

export const saveCommunityFinished = (props: {
  slug: string;
  totalLocationScore: number;
  totalYearScore: number;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/communitygames/finishgame`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify(props),
  });
};

export const saveCommunityVote = (props: {
  slug: string;
  delta: number;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/communitygames/votegame`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify(props),
  });
};

export const saveCommunityGamesCompleted = ({
  communityGamesCompleted,
}: {
  communityGamesCompleted: string;
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/communitygames/savegamescompleted`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      communityGamesCompleted,
    }),
  });
};

export const updateUserProfile = async (user: User): Promise<string | null> => {
  const response = await fetch(`${getAuthServerUrl()}/api/updateprofile`, {
    method: 'POST',
    headers: {
      ...makeAuthServerAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      marketingOptIn: user.marketingOptIn,
      bio: user.bio,
      socials: user.socials,
    }),
  });
  if (response.status === 200) {
    // now update whentaken db
    getUser();
    return null;
  }
  const jsonRes = await response.json();
  return (jsonRes as { message: string }).message;
};

export const uploadUserProfilePhoto = async (
  photo: File
): Promise<string | null> => {
  const formData = new FormData();
  formData.append('photo', photo);

  const response = await fetch(`${getServerUrl()}/api/uploadprofilephoto`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      'Site-Mode': getSiteMode(),
    },
    body: formData,
  });
  if (response.status < 200 || response.status > 299) {
    return null;
  }
  return await response.json();
};

export const getMyGames = async ({
  offset,
  limit,
}: {
  offset: number;
  limit: number;
}): Promise<{
  games: CommunityGame[];
  totalCount: number;
}> => {
  const response = await fetch(
    `${getServerUrl()}/communitygames/getmygames?offset=${offset}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        ...makeAuthorizationHeader(),
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    return {
      games: [],
      totalCount: 0,
    };
  }
  return await response.json();
};

export const getMyGame = async (
  slug: string
): Promise<CommunityGame | null> => {
  const response = await fetch(`${getServerUrl()}/communitygames/getmygame`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      slug,
    }),
  });
  if (response.status < 200 || response.status > 299) {
    return null;
  }
  return await response.json();
};

export const getCommunityGame = async (
  slug: string
): Promise<CommunityGame | null> => {
  const response = await fetch(`${getServerUrl()}/communitygames/findgame`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      slug,
    }),
  });
  if (response.status < 200 || response.status > 299) {
    return null;
  }
  return await response.json();
};

export const getSocialsForUser = async (
  username: string
): Promise<{
  bio: string;
  photoURL: string;
  socials: { platform: string; handle: string }[];
} | null> => {
  const response = await fetch(
    `${getServerUrl()}/api/getsocials?username=${username}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    return null;
  }
  return await response.json();
};

export const getCommunityGames = async (args: {
  sortBy: string;
  daysPeriod: number;
  sortOrder: string;
  offset: number;
  limit: number;
  username?: string | null;
}): Promise<{
  games: CommunityGame[];
  totalCount: number;
}> => {
  const response = await fetch(
    `${getServerUrl()}/communitygames/getgames?sortBy=${args.sortBy}&sortOrder=${args.sortOrder}&daysPeriod=${args.daysPeriod}&offset=${args.offset}&limit=${args.limit}&filterByCreationDate=1` +
      (args.username ? `&username=${args.username}` : ''),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    return {
      totalCount: 0,
      games: [],
    };
  }
  return await response.json();
};

export const createGame = async (
  name: string,
  publicGame: boolean,
  rounds: GameRound[]
): Promise<string | null> => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('public', publicGame ? 'true' : 'false');

  const promises: (File | Blob | Promise<File | Blob>)[] = [];
  rounds.forEach((round) => {
    if (round.image instanceof File) {
      promises.push(round.image);
    } else if (typeof round.image === 'string') {
      promises.push(fetch(round.image).then((r) => r.blob()));
    }
  });
  const files = await Promise.all(promises);
  for (const f of files) {
    formData.append('photos', f);
  }

  const roundData = rounds.map((round) => ({
    latitude: round.location?.latitude,
    longitude: round.location?.longitude,
    year: Number(round.year),
    description: round.description,
    photographer: round.photographer,
  }));
  formData.append('rounds', JSON.stringify(roundData));

  const response = await fetch(`${getServerUrl()}/communitygames/create`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      'Site-Mode': getSiteMode(),
    },
    body: formData,
  });
  if (response.status < 200 || response.status > 299) {
    return null;
  }
  return await response.json();
};

export const editGame = async (
  slug: string,
  name: string,
  publicGame: boolean,
  rounds: GameRound[]
): Promise<boolean> => {
  const formData = new FormData();
  formData.append('slug', slug);
  formData.append('name', name);
  formData.append('public', publicGame ? 'true' : 'false');

  rounds.forEach((round, index) => {
    if (round.image instanceof File) {
      formData.append(`photo${index}`, round.image);
    }
  });

  const roundData = rounds.map((round) => ({
    latitude: round.location?.latitude,
    longitude: round.location?.longitude,
    year: Number(round.year),
    description: round.description,
    photographer: round.photographer,
  }));
  formData.append('rounds', JSON.stringify(roundData));

  const response = await fetch(`${getServerUrl()}/communitygames/edit`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      'Site-Mode': getSiteMode(),
    },
    body: formData,
  });
  if (response.status < 200 || response.status > 299) {
    return false;
  }
  return true;
};

export const deleteCommunityGame = async (slug: string): Promise<boolean> => {
  const response = await fetch(`${getServerUrl()}/communitygames/delete`, {
    method: 'POST',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      slug,
    }),
  });
  if (response.status < 200 || response.status > 299) {
    return false;
  }
  return true;
};

export const getHint = async (
  date: string,
  round: number,
  hint: string
): Promise<LocationHint | YearHint | null> => {
  const response = await fetch(
    `${getServerUrl()}/hint/get?puzzleId=${date}&round=${round}&hint=${hint}`,
    {
      method: 'GET',
      headers: {
        ...makeAuthorizationHeader(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    return null;
  }
  return await response.json();
};

const availableHintsCache: { [date: string]: { [round: number]: string[] } } =
  {};
export const getAvailableHints = async (
  date: string,
  round: number
): Promise<string[]> => {
  if (availableHintsCache?.[date]?.[round]) {
    return availableHintsCache[date][round];
  }
  const response = await fetch(
    `${getServerUrl()}/hint/get?puzzleId=${date}&round=${round}`,
    {
      method: 'GET',
      headers: {
        ...makeAuthorizationHeader(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    return [];
  }
  const result: string[] = await response.json();
  availableHintsCache[date] ||= [];
  availableHintsCache[date][round] = result;
  return result;
};

export const getAllHints = async (
  date: string,
  round: number
): Promise<{
  locationHints: LocationHint[];
  yearHints: YearHint[];
}> => {
  const response = await fetch(
    `${getServerUrl()}/hint/get?puzzleId=${date}&round=${round}&hint=all`,
    {
      method: 'GET',
      headers: {
        ...makeAuthorizationHeader(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    return { locationHints: [], yearHints: [] };
  }
  return await response.json();
};

export const clearHints = async (
  date: string,
  round: number,
  auth: string
): Promise<{
  locationHints: LocationHint[];
  yearHints: YearHint[];
}> => {
  const response = await fetch(
    `${getServerUrl()}/hint/clear?puzzleId=${date}&round=${round}`,
    {
      method: 'POST',
      headers: {
        authorization: auth,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Site-Mode': getSiteMode(),
      },
    }
  );
  if (response.status < 200 || response.status > 299) {
    return { locationHints: [], yearHints: [] };
  }
  return await response.json();
};

export const updateRoundStats = ({
  gameMode,
  guesses,
  scores,
}: {
  gameMode: GameMode;
  guesses: Guess[];
  scores: { year: number; distance: number }[];
}): Promise<Response> => {
  return fetch(`${getServerUrl()}/api/updateroundstats`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
    body: JSON.stringify({
      gameMode,
      guesses,
      scores,
    }),
  });
};

export const getBotData = async (roundId: string): Promise<BotData | null> => {
  const response = await fetch(`${getServerUrl()}/bot/get?roundId=${roundId}`, {
    method: 'GET',
    headers: {
      ...makeAuthorizationHeader(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Site-Mode': getSiteMode(),
    },
  });
  if (response.status < 200 || response.status > 299) {
    return null;
  }
  return await response.json();
};

export const logClientError = async (
  error: Error | { message?: string; stack?: string; name?: string },
  errorInfo?: ErrorInfo
) => {
  try {
    await fetch(`${getServerUrl()}/api/logclienterror`, {
      method: 'POST',
      headers: {
        'Site-Mode': getSiteMode(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        error: {
          message:
            (error.message ?? 'no message') +
            '\n\n' +
            (errorInfo?.digest ?? 'no digest'),
          stack:
            (error.stack ?? 'no error stack') +
            '\n\n' +
            (errorInfo?.componentStack ?? 'no component stack'),
          name: error.name,
        },
      }),
    });
  } catch (e) {
    // Ignore
  }
};
