'use client'

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TeutuefLogo from '@wt/game/assets/logo/TeutefLogo';
import { getSiteMode } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { memo } from 'react';

export const MobileFooterMenu = () => {
  return (
    <div className="flex flex-col items-center gap-4">
      <TeuteufLinks />
      <SocialIcons />
    </div>
  );
};

const SocialIcons = () => {
  return (
    <div className="flex items-start justify-start gap-4">
      <Link
        href={'https://www.facebook.com/teuteufgames'}
        target={'_blank'}
        aria-label="Teuteuf Facebook"
        className="relative size-6 transition-opacity duration-150  ease-linear hover:opacity-80"
      >
        <FacebookRoundedIcon />
      </Link>
      <Link
        href={'https://www.tiktok.com/@teuteufgames'}
        target={'_blank'}
        aria-label="Teuteuf TikTok"
        className="relative size-6 transition-opacity duration-150  ease-linear hover:opacity-80"
      >
        <img src={'/assets/images/tiktok.svg'} className="dark:invert" />
      </Link>
      <Link
        href={'https://www.instagram.com/teuteufgames'}
        target={'_blank'}
        aria-label="Teuteuf Instagram"
        className="relative size-6 transition-opacity duration-150  ease-linear hover:opacity-80"
      >
        <InstagramIcon />
      </Link>
    </div>
  );
};

const TeuteufLinks = () => {
  return (
    <>
      {getSiteMode() == 'whentaken' && (
        <Link
          href={'/community/guidelines'}
          aria-label="View our Community Guidelines"
          className="font-normal transition-opacity duration-150 ease-linear hover:opacity-80"
        >
          Community Guidelines
        </Link>
      )}
      <Link
        href={'/privacy-policy'}
        aria-label="View our Privacy Policy"
        className="font-normal transition-opacity duration-150 ease-linear hover:opacity-80"
      >
        Privacy Policy
      </Link>
      <Link
        href={'https://teuteuf.fr/terms-and-conditions/'}
        target={'_blank'}
        aria-label="View our Terms and Conditions"
        className="font-normal transition-opacity duration-150 ease-linear hover:opacity-80"
      >
        Terms & Conditions
      </Link>
    </>
  );
}

const PageFooter = memo(
  () => {
    return (
      <div className="flex items-center justify-center p-4 md:mx-5 md:px-6">
        <footer className="inline-flex w-full items-end justify-between  ">
          <TeutuefLogo />
          <nav className="hidden items-center justify-end gap-4 md:flex">
            <div className="flex items-start justify-start gap-4 text-lg font-semibold">
              <TeuteufLinks />
            </div>
            <SocialIcons />
          </nav>
        </footer>
      </div>
    );
  },
);

export default PageFooter;
